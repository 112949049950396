<template>
    <div id="orderDetails">
        <div class="headBox">
            <el-button
                type="primary"
                icon="el-icon-caret-left"
                plain
                size="mini"
                @click="$router.push({ name: 'paymentOrder' })"
                >返回</el-button
            >
            <div class="title">查看订单详情</div>
        </div>
        <div>
            <el-card class="box-card infoBox" shadow="hover">
                <div class="clearfix" slot="header">
                    <div class="header_tit">客户信息</div>
                    <i class="el-icon-paperclip"></i>
                </div>

                <div class="infoItem">
                    <span class="tit">客户姓名：</span><span>{{ checkForm.customerName }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">身份证号码：</span><span>{{ checkForm.idCard }}</span>
                </div>
                <div class="infoItem zjzInfo">
                    <span class="tit">证件照：</span>
                    <div class="zjz">
                        <div class="img">
                            <img
                                :src="frontImageUrl"
                                alt=""
                                @click="
                                    showbigImg = true;
                                    bigImgSrc = frontImageUrl;
                                "
                            />
                        </div>
                        <div class="img">
                            <img
                                :src="backImageUrl"
                                alt=""
                                @click="
                                    showbigImg = true;
                                    bigImgSrc = backImageUrl;
                                "
                            />
                        </div>
                    </div>
                </div>
            </el-card>
            <el-card class="box-card infoBox" shadow="hover">
                <div class="clearfix" slot="header">
                    <div class="header_tit">订单信息</div>
                    <i class="el-icon-paperclip"></i>
                </div>
                <div class="infoItem">
                    <span class="tit">订单号：</span><span>{{ checkForm.id }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">签约银行：</span><span>{{ checkForm.signingBank }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">银行卡号：</span><span>{{ checkForm.bankCard }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">银行预留手机号：</span><span>{{ checkForm.phoneNumber }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">签约金额：</span><span>{{ checkForm.signingAmount }} 元</span>
                </div>
                <div class="infoItem">
                    <span class="tit">支付平台：</span><span>{{ checkForm.paymentPlatformTypeDesc }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">是否分期：</span><span>{{ checkForm.isStage ? "是" : "否" }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">分期数：</span><span>{{ checkForm.stageNumber }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">签约状态：</span><span>已签约</span>
                </div>
            </el-card>

            <el-card class="box-card infoBox" shadow="hover">
                <div class="clearfix" slot="header">
                    <div class="header_tit">分期明细</div>
                    <i class="el-icon-paperclip"></i>
                </div>
                <el-table :data="fqTableData" style="width: 100%; padding: 0 10px" class="table">
                    <el-table-column prop="stage" label="分期数" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row["stage"] + "/" + checkForm.stageNumber }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" label="日期" align="center"></el-table-column>
                    <el-table-column prop="amount" label="扣款金额(元)" align="center">
                        <template slot-scope="scope">
                            <span style="position: relative">
                                {{ scope.row["amount"] | numberToCurrency(2) }}
                                <i
                                    class="el-icon-success status"
                                    style="color: #67c23a; position: absolute; right: -60px; top: 0"
                                    v-if="scope.row.stage < checkForm.currentStage"
                                ></i>
                                <i
                                    class="el-icon-success status"
                                    style="color: #ccc; position: absolute; right: -60px; top: 0"
                                    v-else
                                ></i>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </div>
        <checkImg :showbigImg="showbigImg" :bigImgSrc="bigImgSrc" @update:showbigImg="handleBigImageUpdate"></checkImg>
    </div>
</template>
<script>
import checkImg from "../../components/comm/checkImg.vue";
import paymentOrderApi from "../../api/paymentOrderApi";
export default {
    name: "orderDetails",
    components: { checkImg },

    data() {
        return {
            checkId: null,
            checkForm: {},
            showbigImg: false,
            bigImgSrc: "",
            fqTableData: [],
            frontImageUrl: "",
            backImageUrl: ""
        };
    },
    mounted() {
        this.checkId = this.$route.query.id;

        this.getData();
        this.getStageInfo();
    },
    methods: {
        handStatus(status) {
            switch (status) {
                case 0:
                    return "未签约";
                case 1:
                    return "已签约";
                case 2:
                    return "签约中";
                case 3:
                    return "已解约";
            }
        },

        getData() {
          
                paymentOrderApi
                    .orderList({

                        id: this.$route.query.id

                    })
                    .then(res => {
                        if (res.success) {
                            this.$set(res.data.records[0], "isStage", res.data.records[0].isStage ? true : false);
                            this.checkForm = res.data.records[0];
                           

                            if (res.data.records[0].idCardFrontImageSrc) {
                                this.frontImageUrl = "//safepayment.com.cn:18081/api/attachment" + res.data.records[0].idCardFrontImageSrc+'?token='+this.$utils.storage.get('X-Access-Token');
                            }
                            if (res.data.records[0].idCardBackImageSrc) {
                                this.backImageUrl = "//safepayment.com.cn:18081/api/attachment" + res.data.records[0].idCardBackImageSrc+'?token='+this.$utils.storage.get('X-Access-Token');
                            }
                        }
                    });
           
        },

        getStageInfo() {
            paymentOrderApi
                .stageInfo({
                    id: this.checkId
                })
                .then(res => {
                    if (res.success) {
                        this.fqTableData = res.data;
                    }
                });
        },

        handleBigImageUpdate(newValue) {
            this.showbigImg = newValue;
        }
    }
};
</script>
<style>
</style>
<style lang="scss" scoped>
#orderDetails {
    background: #ffffff;
    padding: 20px 20px 20px 17%;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
        padding-right: 100px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        width: 80%;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
        }
    }
    .header_tit {
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
    }

    .infoBox {
        width: 80%;
        .infoItem {
            border-bottom: 1px solid #eee;
            padding: 0 12px 4px 12px;
            margin: 0 10px 20px;
            display: flex;
            justify-content: space-between;
            &:last-child {
                border: 0;
            }
            .tit {
                font-weight: 700;
            }
            .zjz {
                margin-left: 50px;
                margin-top: 5px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                .img {
                    width: 120px;
                    height: 120px;
                    border: 1px dashed #d9d9d9;
                    margin-top: 15px;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .zjzInfo {
            display: block;
            margin: 0 10px 15px;
        }
        .table {
            font-size: 12px;
        }
    }

    .box-card {
        margin-bottom: 20px;
        width: 80%;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
        .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        width: 80%;
    }
}
@media (max-width: 600px) {
    #orderDetails {
        padding: 16px;
        .title {
            padding-right: 0;
        }
        .box-card {
            width: 100%;
        }
        .headBox {
            width: 100%;
        }
        .zjz {
            flex-wrap: wrap;
        }
        .footer {
            width: 100%;
        }
    }
}
</style>

